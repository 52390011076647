import { Turbo } from '@hotwired/turbo-rails';

interface TriggerTurboStreamProps {
  src: string | URL;
  history?: 'replace' | 'advance' | 'ignore';
  forceReplace?: boolean;
}

const TurboStreamAcceptHeader = 'text/vnd.turbo-stream.html';

export const triggerTurboStream = ({
  src,
  forceReplace = false,
  history = 'ignore',
}: TriggerTurboStreamProps) => {
  const headers: HeadersInit = { Accept: TurboStreamAcceptHeader };
  if (forceReplace) headers['Turbo-Stream-Force-Update'] = 'true';

  return fetch(src, { headers: headers })
    .then((r) => r.text())
    .then((html) => Turbo.renderStreamMessage(html))
    .then(() => {
      if (history === 'ignore') return;
      else if (history === 'replace') {
        Turbo.session.history.replace(src);
      } else {
        Turbo.session.history.push(src);
      }
    });
};
